import Control from "./Control";
import Controls from "./controls";
import Result from "./Result";
import Results from "./results";

export default {
  Control,
  Controls,
  Result,
  Results
};
