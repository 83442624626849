import Audio from "./Audio";
import Document from "./Document";
import File from "./File";
import Image from "./Image";
import Interactive from "./Interactive";
import Link from "./Link";
import Pdf from "./Pdf";
import Presentation from "./Presentation";
import Spreadsheet from "./Spreadsheet";
import Variants from "./Variants";
import Video from "./Video";

export default {
  Audio,
  Document,
  File,
  Image,
  Interactive,
  Link,
  Pdf,
  Presentation,
  Spreadsheet,
  Variants,
  Video
};
