import FollowLink from "./FollowLink";
import Login from "./Login";
import Main from "./Main/index";
import ReadingGroup from "./ReadingGroup";
import Share from "./Share";

export default {
  FollowLink,
  Login,
  Main,
  ReadingGroup,
  Share
};
