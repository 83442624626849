import Edit from "./Edit";
import Form from "./Form";
import List from "./List";
import New from "./New";

export default {
  Edit,
  Form,
  List,
  New
};
