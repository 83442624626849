import Edit from "./Edit";
import Fetch from "./Fetch";
import Static from "./Static";
import Wrapper from "./Wrapper";

export default {
  Edit,
  Fetch,
  Static,
  Wrapper
};
