import close from "./close";
import full from "./full";
import fullHeader from "./fullHeader";
import title from "./title";

export default `
  ${full}
  ${fullHeader}
  ${close}
  ${title}
`;
