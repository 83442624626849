import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function RTELink(props) {
  const { className, size, fill, svgProps } = props;

  const getSize = () => {
    if (size === "inherit") return null;
    if (!size || size === "default") return 24;
    return size;
  };
  const baseSvgProps = {
    xmlns: "http://www.w3.org/2000/svg",
    className: classNames("manicon-svg", className),
    width: getSize(),
    height: getSize(),
    viewBox: "2 2 24 24",
    fill
  };
  return (
    <svg {...baseSvgProps} {...svgProps}>
      <path d="M15.875 12.75C16.2458 12.75 16.6084 12.64 16.9167 12.434C17.225 12.228 17.4654 11.9351 17.6073 11.5925C17.7492 11.2499 17.7863 10.8729 17.714 10.5092C17.6416 10.1455 17.463 9.8114 17.2008 9.54917C16.9386 9.28695 16.6045 9.10837 16.2408 9.03603C15.8771 8.96368 15.5001 9.00081 15.1575 9.14273C14.8149 9.28464 14.522 9.52496 14.316 9.83331C14.11 10.1416 14 10.5042 14 10.875C14 11.3723 14.1975 11.8492 14.5492 12.2008C14.9008 12.5525 15.3777 12.75 15.875 12.75ZM15.875 10.25C15.9986 10.25 16.1195 10.2867 16.2222 10.3553C16.325 10.424 16.4051 10.5216 16.4524 10.6358C16.4997 10.75 16.5121 10.8757 16.488 10.9969C16.4639 11.1182 16.4044 11.2295 16.3169 11.3169C16.2295 11.4043 16.1182 11.4639 15.9969 11.488C15.8757 11.5121 15.75 11.4997 15.6358 11.4524C15.5216 11.4051 15.424 11.325 15.3553 11.2222C15.2867 11.1195 15.25 10.9986 15.25 10.875C15.25 10.7092 15.3158 10.5503 15.4331 10.4331C15.5503 10.3158 15.7092 10.25 15.875 10.25Z" />
      <path d="M20.25 6.5H7.75C7.41848 6.5 7.10054 6.6317 6.86612 6.86612C6.6317 7.10054 6.5 7.41848 6.5 7.75V20.25C6.5 20.5815 6.6317 20.8995 6.86612 21.1339C7.10054 21.3683 7.41848 21.5 7.75 21.5H20.25C20.5815 21.5 20.8995 21.3683 21.1339 21.1339C21.3683 20.8995 21.5 20.5815 21.5 20.25V7.75C21.5 7.41848 21.3683 7.10054 21.1339 6.86612C20.8995 6.6317 20.5815 6.5 20.25 6.5ZM20.25 20.25H7.75V16.5L10.875 13.375L14.3688 16.8687C14.603 17.1016 14.9198 17.2322 15.25 17.2322C15.5802 17.2322 15.897 17.1016 16.1313 16.8687L17.125 15.875L20.25 19V20.25ZM20.25 17.2312L18.0063 14.9875C17.772 14.7547 17.4552 14.624 17.125 14.624C16.7948 14.624 16.478 14.7547 16.2437 14.9875L15.25 15.9812L11.7562 12.4875C11.522 12.2547 11.2052 12.124 10.875 12.124C10.5448 12.124 10.228 12.2547 9.99375 12.4875L7.75 14.7312V7.75H20.25V17.2312Z" />
    </svg>
  );
}

RTELink.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stroke: PropTypes.string,
  fill: PropTypes.string,
  svgProps: PropTypes.object
};

export default RTELink;
