import Properties from "./Properties";
import New from "./New";
import Resources from "./Resources";
import Wrapper from "./Wrapper";

export default {
  Properties,
  New,
  Resources,
  Wrapper
};
