/* eslint-disable import/no-named-as-default */
import NewAnnotation from "./NewAnnotation";
import NewNotation from "./NewNotation";
import ViewAnnotations from "./ViewAnnotations";
import Citation from "./Citation";

export default {
  NewAnnotation,
  NewNotation,
  ViewAnnotations,
  Citation
};
/* eslint-enable import/no-named-as-default */
