import AllLink from "./AllLink";
import List from "./List/index";
import Event from "./Event";
import Tile from "./Tile/index";

export default {
  AllLink,
  List,
  Event,
  Tile
};
