import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

function RTELink(props) {
  const { className, size, fill, svgProps } = props;

  const getSize = () => {
    if (size === "inherit") return null;
    if (!size || size === "default") return 24;
    return size;
  };
  const baseSvgProps = {
    xmlns: "http://www.w3.org/2000/svg",
    className: classNames("manicon-svg", className),
    width: getSize(),
    height: getSize(),
    viewBox: "2 2 24 24",
    fill
  };
  return (
    <svg {...baseSvgProps} {...svgProps}>
      <path d="M22.2813 8.22405C21.9329 7.87445 21.5189 7.59706 21.0631 7.40779C20.6073 7.21852 20.1186 7.12109 19.625 7.12109C19.1315 7.12109 18.6428 7.21852 18.187 7.40779C17.7312 7.59706 17.3172 7.87445 16.9688 8.22405L17.8563 9.11155C18.089 8.87886 18.3652 8.69429 18.6692 8.56836C18.9733 8.44243 19.2991 8.37762 19.6282 8.37762C19.9572 8.37762 20.2831 8.44243 20.5871 8.56836C20.8911 8.69429 21.1674 8.87886 21.4 9.11155C21.6327 9.34424 21.8173 9.62047 21.9432 9.92449C22.0692 10.2285 22.134 10.5544 22.134 10.8834C22.134 11.2125 22.0692 11.5383 21.9432 11.8424C21.8173 12.1464 21.6327 12.4226 21.4 12.6553L16.4 17.6553C15.9309 18.1252 15.2944 18.3896 14.6304 18.3901C13.9664 18.3907 13.3293 18.1275 12.8594 17.6584C12.3895 17.1893 12.1252 16.5528 12.1246 15.8888C12.124 15.2248 12.3872 14.5877 12.8563 14.1178L13.7375 13.2303L12.8563 12.3428L11.9688 13.2303C11.6192 13.5787 11.3418 13.9927 11.1525 14.4485C10.9633 14.9043 10.8658 15.393 10.8658 15.8865C10.8658 16.3801 10.9633 16.8688 11.1525 17.3246C11.3418 17.7804 11.6192 18.1944 11.9688 18.5428C12.676 19.2409 13.6313 19.6298 14.625 19.624C15.1205 19.6261 15.6114 19.5299 16.0695 19.3411C16.5276 19.1523 16.9437 18.8746 17.2938 18.524L22.2938 13.524C22.9944 12.8193 23.3866 11.8652 23.3842 10.8715C23.3819 9.87771 22.9852 8.92552 22.2813 8.22405Z" />
      <path d="M6.61879 19.5115C6.38541 19.2793 6.20022 19.0031 6.07386 18.6991C5.94749 18.395 5.88244 18.069 5.88244 17.7397C5.88244 17.4104 5.94749 17.0844 6.07386 16.7803C6.20022 16.4762 6.38541 16.2001 6.61879 15.9678L11.6188 10.9678C11.8511 10.7344 12.1272 10.5492 12.4313 10.4229C12.7353 10.2965 13.0614 10.2314 13.3907 10.2314C13.7199 10.2314 14.046 10.2965 14.3501 10.4229C14.6541 10.5492 14.9302 10.7344 15.1625 10.9678C15.3944 11.2019 15.577 11.4802 15.6994 11.7862C15.8218 12.0922 15.8815 12.4196 15.875 12.7491C15.8769 13.0796 15.8133 13.4072 15.6878 13.7129C15.5623 14.0186 15.3774 14.2965 15.1438 14.5303L13.8188 15.8741L14.7063 16.7615L16.0313 15.4365C16.7366 14.7312 17.1328 13.7746 17.1328 12.7772C17.1328 11.7797 16.7366 10.8231 16.0313 10.1178C15.326 9.41249 14.3694 9.01625 13.3719 9.01625C12.3745 9.01625 11.4179 9.41249 10.7125 10.1178L5.71254 15.1178C5.362 15.4663 5.08382 15.8807 4.89399 16.3371C4.70417 16.7935 4.60645 17.2829 4.60645 17.7772C4.60645 18.2715 4.70417 18.7609 4.89399 19.2173C5.08382 19.6737 5.362 20.088 5.71254 20.4365C6.42431 21.1293 7.38185 21.5115 8.37504 21.499C9.37698 21.5 10.3386 21.1046 11.05 20.399L10.1625 19.5115C9.93025 19.7449 9.65413 19.9301 9.35006 20.0565C9.04599 20.1829 8.71995 20.2479 8.39067 20.2479C8.06138 20.2479 7.73534 20.1829 7.43127 20.0565C7.1272 19.9301 6.85109 19.7449 6.61879 19.5115Z" />
    </svg>
  );
}

RTELink.propTypes = {
  className: PropTypes.string,
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  stroke: PropTypes.string,
  fill: PropTypes.string,
  svgProps: PropTypes.object
};

export default RTELink;
