import Wrapper from "./Wrapper";
import TopProjects from "./TopProjects";
import TopSearches from "./TopSearches";
import Global from "./Global";

export default {
  Wrapper,
  TopProjects,
  TopSearches,
  Global
};
