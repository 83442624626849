import List from "./List";
import New from "./New";
import Detail from "./Detail";
import Properties from "./Properties";

export default {
  List,
  New,
  Detail,
  Properties
};
