import Wrapper from "./Wrapper";
import New from "./New";
import Results from "./Results";
import Map from "./Map";

export default {
  Wrapper,
  New,
  Results,
  Map
};
